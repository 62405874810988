<template>
  <footer class="footer">
    <div class="content">
      <div class="phone">
        <div class="label">
          <div class="img">
            <img src="../assets/images/phone.svg" alt="" />
          </div>
          <div class="text">成为刘小白合伙人请拨打</div>
        </div>
        <div class="num">4006-21-4006</div>
      </div>
      <div class="introduction">
        <p>浙江刘小白智能科技有限公司</p>
        <p>
          <span
            >Copyright©2021 Zhejiang Liuxiaobai Smart Technology Co., Ltd. All
            rights reserved..
          </span>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >浙ICP备17028218号-1</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="less" scoped>
.footer {
  width: 100vw;
  height: 250px;
  background: linear-gradient(180deg, #fefefe 0%, #e9ecf1 100%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    margin: 0 auto;
    box-sizing: border-box;
    width: 1200px;
    height: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .phone {
      display: flex;
      flex-direction: column;
      align-items: center;
      .label {
        display: flex;
        .img {
          width: 28px;
          height: 28px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          margin-left: 8px;
          height: 28px;
          font-size: 20px;
          color: #666666;
          line-height: 28px;
        }
      }
      .num {
        height: 56px;
        font-size: 40px;
        font-weight: bold;
        color: #333333;
        line-height: 56px;
      }
    }
    .introduction {
      width: 1070px;
      height: 40px;
      font-size: 14px;
      color: #666666;
      line-height: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        text-decoration: underline;
        font-size: 14px;
        color: #666666;
        line-height: 22px;
      }
    }
  }
}
</style>
